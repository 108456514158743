import { useEffect } from 'react'
import type { GetStaticPropsContext } from 'next'
import commerce from '@lib/api/commerce'
import { Layout } from '@components/common'
import { BlankPage } from '@components/staticpage'
import { useUI } from '@components/ui/context'

export async function getStaticProps({
  preview,
  locale,
  locales,
}: GetStaticPropsContext) {
  const config = { locale, locales }
  const { pages } = await commerce.getAllPages({ config, preview })
  const { categories, brands } = await commerce.getSiteInfo({ config, preview })
  return {
    props: {
      pages,
      categories,
      brands,
      error: '404',
    },
    revalidate: 200,
  }
}

export default function NotFound() {
  const { setPageTheme } = useUI()
  useEffect(() => {
    setPageTheme('DARK')
  }, [])
  return (
    <>
      <BlankPage
        data={{
          title: 'Not Found',
          detail:
            "The requested page doesn't exist or you don't have access to it.",
        }}
      ></BlankPage>
    </>
  )
}

NotFound.Layout = Layout
