import cn from 'clsx'
import s from './BlankPage.module.scss'
import { FC } from 'react'
import {  Text } from '@components/ui'

type BlankPageProps = {
  data?: {
    title: string
    detail?: string
  }
  children?: any
}

const BlankPage: FC<BlankPageProps> = ({ data, children }) => {
  return (
    <>
      <div className={s.root}>
        <Text html={data?.title} className={s.title} variant="pageHeading" />

        {data?.detail && (
          <Text html={data.detail} className={s.detail} variant="smallText" />
        )}

        {children}
      </div>
    </>
  )
}

export default BlankPage
